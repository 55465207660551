<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
    <div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
                <div class="flex flex-wrap justify-content-between flex-row grid">
                    <div class="flex justify-content-start col">
                        <h5 class="mt-auto mb-auto">Tambah Donasi</h5>
                    </div>
                </div>
                <h6 class="mt-auto mb-2"><i>{{ tgl_hari_ini }}</i></h6>
                <small>* wajib diisi</small>
                <form class="p-fluid">
                    <fieldset :disabled="loadingRegister">
                        <div class="form-group">
                            <div class="field">
                                <label for="donatur">Donatur *</label>
                                <InputText id="donatur" v-model="nama_donatur" placeholder="Pilih Donatur" @click="toggleDataDonatur" readonly :class="{'p-invalid':submittedDonasi && !nama_donatur}"/>
                                <small class="p-error" v-if="submittedDonasi && !nama_donatur">Donatur harus diisi.</small>
                                <Dialog v-model:visible="donaturEntry" header=" " :modal="true" class="p-fluid w-full" :closable="false" :maximizable="true">
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="flex flex-wrap justify-content-between flex-row grid">
                                                    <div class="flex flex-column justify-content-start col">
                                                        <h5 class="mt-auto mb-auto">Daftar Donatur</h5>
                                                        <small class="mt-auto mb-auto"><i>*pilih salah satu donatur</i></small>
                                                    </div>
                                                    <div class="flex justify-content-end col">
                                                        <Button label="Donatur Baru" icon="pi pi-pencil" class="p-button-text" @click="openDonaturEntry"></Button>
                                                    </div>
                                                </div>
                                                <DataTable :value="listDonatur" :paginator="true" stripedRows class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
                                                            v-model:filters="filters1" filterDisplay="row" :loading="loading" :filters="filters1" responsiveLayout="scroll"
                                                            :globalFilterFields="['nama', 'alamat', 'telepon', 'email']"
                                                            selectionMode="single" @rowSelect="onDonaturSelect" v-model:selection="selectedDonatur">
                                                    
                                                    <template #header>
                                                        <div class="flex justify-content-between flex-column sm:flex-row">
                                                            <Button type="button" icon="pi pi-filter-slash" label="Reset Pencarian" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                                                            <span class="p-input-icon-left mb-2">
                                                                <i class="pi pi-search" />
                                                                <InputText v-model="filters1['global'].value" placeholder="Cari" style="width: 100%"/>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template #empty>
                                                        Daftar Donatur tidak ditemukan...
                                                    </template>
                                                    <template #loading>
                                                        Memuat daftar Donatur. Mohon menunggu...
                                                    </template>
                                                    <Column field="nama" header="Nama" style="min-width:12rem" :sortable="true">
                                                        <template #body="{data}">
                                                            {{data.nama}}
                                                        </template>
                                                        <template #filter="{filterModel,filterCallback}">
                                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari nama" @input="filterCallback()"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="email" header="Email" style="min-width:12rem" :sortable="true">
                                                        <template #body="{data}">
                                                            {{data.email}}
                                                        </template>
                                                        <template #filter="{filterModel,filterCallback}">
                                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari email" @input="filterCallback()"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="telepon" header="Telepon" style="min-width:12rem" :sortable="true">
                                                        <template #body="{data}">
                                                            {{data.telepon}}
                                                        </template>
                                                        <template #filter="{filterModel,filterCallback}">
                                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari telepon" @input="filterCallback()"/>
                                                        </template>
                                                    </Column>
                                                    <Column field="alamat" header="Alamat" style="min-width:12rem" :sortable="true">
                                                        <template #body="{data}">
                                                            {{data.alamat}}
                                                        </template>
                                                        <template #filter="{filterModel,filterCallback}">
                                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari alamat" @input="filterCallback()"/>
                                                        </template>
                                                    </Column>
                                                    
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                    <template #footer>
                                        <Button label="Batalkan" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                                    </template>
                                </Dialog>
                            </div>
                            <div class="field">
                                <label for="nominal">Nominal *</label>
                                <InputNumber id="nominal" v-model="nominal" mode="currency" currency="IDR" locale="id-ID" :class="{'p-invalid':submittedDonasi && !nominal}" :maxFractionDigits="0" :min="0" @input="currencyToText" :step="1000" />
                                <small class="p-error" v-if="submittedDonasi && !nominal">Nominal harus diisi.</small>
                            </div>
                            <div class="field">
                                <label for="terbilang">Terbilang</label>
                                <InputText id="terbilang" placeholder="Nol Rupiah" :value="terbilang" readonly />
                            </div>
                            <div class="field">
                                <label for="keterangan">Keterangan (opsional)</label>
                                <Textarea id="keterangan" v-model="keterangan" placeholder="Tambahkan keterangan" :autoResize="true" rows="5" cols="30"  />
                            </div>
                            
                        </div>
                    </fieldset>
                </form>
                <div class="flex justify-content-end col">
                    <Button label="Simpan" icon="pi pi-inbox" class="p-button-rounded" @click="simpanDonasi" />
                </div>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="newDonaturEntry" :style="{width: '600px'}" header="Tambah Donatur" :modal="true" class="p-fluid" :closable="false" :maximizable="true">
		<small>* wajib diisi</small>
        <form class="p-fluid">
            <fieldset :disabled="loadingRegister">
                <div class="form-group">
                    <div class="field">
                        <label for="nama">Nama Donatur *</label>
                        <InputText id="nama" v-model="nama" :class="{'p-invalid':submittedDonatur && !nama}" placeholder="Nama Lengkap" />
                        <small class="p-error" v-if="submittedDonatur && !nama">Nama harus diisi.</small>
                    </div>
					<div class="field">
                        <label for="jenis_kelamin" class="mb-3">Jenis Kelamin *</label>
                        <Dropdown id="jenis_kelamin" v-model="jenis_kelamin" :options="jenis_kelamins" optionLabel="label" optionValue="value" placeholder="Pilih jenis kelamin" required/>
                    </div>
					<div class="field">
						<label for="tgl_lahir" class="mb-3">Tanggal Lahir</label>
						<Calendar id="tgl_lahir" v-model="tgl_lahir"  dateFormat="yy-mm-dd" placeholder="yyyy-mm-dd" :manualInput="false" />
					</div>
					<div class="field">
                        <label for="no_hp">No Telp.</label>
                        <InputText id="no_hp" v-model="telepon" placeholder="08xx-xxxx-xxxx" />
                    </div>
                    <div class="field">
                        <label for="email">Email</label>
                        <InputText id="email" v-model="email" placeholder="contoh@email.com" />
                    </div>
					<div class="field">
                        <label for="pekerjaan">Pekerjaan/Perusahaan</label>
                        <InputText id="pekerjaan" v-model="pekerjaan" placeholder="Jabatan/Nama Perusahaan" />
                    </div>
					<div class="field">
                        <label for="alamat">Alamat Lengkap *</label>
                        <Textarea id="alamat" v-model="alamat" :class="{'p-invalid':submittedDonatur && !alamat}" placeholder="Alamat Lengkap (Jalan, Kecamatan, Kelurahan, Kota/Kabupaten, dll...)" :autoResize="true" rows="5" cols="30"  />
                        <small class="p-error" v-if="submittedDonatur && !alamat">Alamat harus diisi.</small>
                    </div>
                </div>
            </fieldset>
        </form>
        <template #footer>
            <Button label="Batalkan" icon="pi pi-times" class="p-button-text" @click="hideDialogNewDonatur"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="newDonatur" :disabled="!nama || !alamat" />
        </template>
    </Dialog>
    <Toast></Toast>
</template>

<script>
    import Donasi from '../api/Donasi'
	import Donatur from '../api/Donatur'
	import {FilterMatchMode} from 'primevue/api';

    export default {
        name: 'DetailDonatur',
        created() {
			this.donaturList = new Donatur();
			this.donasi = new Donasi();
            console.log(this.tgl_donasi);
        },
        data() {
            return {
                form: {},
                nominal: 0,
                terbilang: '',
                formDonatur: {},
				nama: '',
				email: '',
				tgl_lahir: '',
				jenis_kelamin: 'L',
				jenis_kelamins: [
                    {label: 'Laki-laki', value: 'L'},
                    {label: 'Perempuan', value: 'P'},
                ],
				telepon: '',
				pekerjaan: '',
				alamat: '',
				submittedDonasi: false,
				submittedDonatur: false,
                loadingRegister: false,
				tgl_hari_ini: this.getTodayDate(),
				tgl_donasi: new Date(),
                listDonatur: null,
                id_donatur: '',
                nama_donatur: '',
                alamat_donatur: '',
                selectedDonatur: null,
                donaturEntry: false,
                newDonaturEntry: false,
                keterangan: '',
                filters1: {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'telepon': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'alamat': {value: null, matchMode: FilterMatchMode.CONTAINS},
				},
            }
        },
        methods: {
            getTodayDate() {
                const today = new Date()
                return today.toLocaleDateString('id-ID', {
                    weekday: 'long',
					day: '2-digit',
					month: 'long',
					year: 'numeric',
				});
            },
            show() {
                console.log(this.tgl_donasi);
            },
            toggleDataDonatur() {
                this.newDonaturEntry = false;
                this.donaturEntry = true;
			},
            openDonaturEntry() {
                this.donaturEntry = false;
                this.newDonaturEntry = true;
            },
            hideDialog() {
                this.donaturEntry = false;
                this.newDonaturEntry = false;
            },
            hideDialogNewDonatur() {
                this.newDonaturEntry = false;
                this.donaturEntry = true;
            },
            newDonatur() {
				this.submittedDonatur = true;
				this.formDonatur = {
                    nama: this.nama,
                    alamat: this.alamat,
                    jenis_kelamin: this.jenis_kelamin,
                    tgl_lahir: this.tgl_lahir,
                    email: this.email,
                    telepon: this.telepon,
                    pekerjaan: this.pekerjaan,
					id_amil: this.$store.state.user.id,
					nama_amil: this.$store.state.user.nama
                }
				this.donaturList.store(this.formDonatur)
					.then(async response => {
						this.nama = '';
						this.alamat = '';
						this.jenis_kelamin = 'L';
						this.tgl_lahir = '';
						this.email = '';
						this.telepon = '';
						this.pekerjaan = '';
						this.id_amil = '';
						this.nama_amil = '';
						this.loadingRegister = false;
						this.donaturEntry = false;
                        this.submittedDonatur = false;
						this.$toast.add({severity:'info', summary: 'Berhasil', detail: 'Berhasil menambahkan donatur', life: 3000});
						this.donaturList.getList()
							.then(response => {
								this.listDonatur = response.data;
								this.loading = false;
                                this.toggleDataDonatur();
							});
						})
					.catch(error => {
					})
			},
            onDonaturSelect(event) {
                this.donaturEntry = false;
                this.id_donatur = event.data.id;
				this.nama_donatur = event.data.nama;
				this.alamat_donatur = event.data.alamat;
            },
            currencyToText(data) {
                let a = ['','Satu ','Dua ','Tiga ','Empat ', 'Lima ','Enam ','Tujuh ','Delapan ','Sembilan ','Sepuluh ','Sebelas ','Dua Belas ','Tiga Belas ','Empat Belas ','Lima Belas ','Enam Belas ','Tujuh Belas ','Delapan Belas ','Sembilan Belas '];
                let b = ['', 'Sepuluh ', 'Dua Puluh ','Tiga Puluh ','Empat Puluh ','Lima Puluh ', 'Enam Puluh ','Tujuh Puluh ','Delapan Puluh ','Sembilan Puluh '];
                let str = '';
                if (data.value > 2147483647) return this.terbilang = 'Melebihi cakupan';
                let n = ('00000000000' + data.value).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
                if (!n) return;
                str += (n[1] != 0) ? (Number(n[1][0]) == 1 ? 'Seratus ' : Number(n[1]) > 99 ? a[Number(n[1][0])] + 'Ratus ' : '') + (a[Number(n[1])] || b[n[1][1]] + '' + a[n[1][2]]) + 'Miliar ' : '';
                str += (n[2] != 0) ? (Number(n[2][0]) == 1 ? 'Seratus ' : Number(n[2]) > 99 ? a[Number(n[2][0])] + 'Ratus ' : '') + (a[Number(n[2])] || b[n[2][1]] + '' + a[n[2][2]]) + 'Juta ' : '';
                str += (n[3] != 0) ? (n[3] == 1) ? 'Seribu ' : (Number(n[3][0]) == 1 ? 'Seratus ' : Number(n[3]) > 99 ? a[Number(n[3][0])] + 'Ratus ' : '') + (a[Number(n[3])] || b[n[3][1]] + '' + a[n[3][2]]) + 'Ribu ' : '';
                str += (n[4] != 0) ? (n[4] == 1) ? 'Seratus ' : (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Ratus ' : '';
                str += (Number(n[5]) !== 0) ? ((str !== '') ? '' : '') +
                            (a[Number(n[5])] || b[n[5][0]] + '' +
                                a[n[5][1]]) + '' : '';
                this.terbilang = str + "Rupiah";
            },
            clearFilter1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'telepon': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'alamat': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
            simpanDonasi() {
                this.submittedDonasi = true;
				this.form = {
                    tgl_donasi: this.tgl_donasi,
                    id_donatur: this.id_donatur,
                    nama_donatur: this.nama_donatur,
                    alamat_donatur: this.alamat_donatur,
					id_amil: this.$store.state.user.id,
					nama_amil: this.$store.state.user.nama,
					nominal: this.nominal,
					keterangan: this.keterangan,
                }
                // console.log(this.form)
                this.donasi.store(this.form)
                    .then(async response => {
                        this.$router.push({name: 'DonasiSukses', params: { idDonasi: response.data.id} })
                    }).catch(error => {
                        console.log(error.response);
                    })
            },
            editDonatur() {
                this.form = {
                    id: this.detailDonatur.id,
                    nama: this.detailDonatur.nama,
                    alamat: this.detailDonatur.alamat,
                    jenis_kelamin: this.detailDonatur.jenis_kelamin,
                    tgl_lahir: this.detailDonatur.tgl_lahir,
                    email: this.detailDonatur.email,
                    telepon: this.detailDonatur.telepon,
                    pekerjaan: this.detailDonatur.pekerjaan,
					id_amil: this.$store.state.user.id,
					nama_amil: this.$store.state.user.nama
                }
                this.donaturDetail.update(this.detailDonatur.id, this.form)
					.then(async response => {
						this.donaturEntry = false
						this.$toast.add({severity:'success', summary: 'Berhasil', detail: 'Berhasil mengedit donatur', life: 3000});
						this.donaturDetail.getDetail(this.idDonatur)
                            .then(async response => {
                                this.detailDonatur = response.data
                            })
						this.loadingRegister = false
						})
					.catch(error => {
						this.$toast.add({severity:'error', summary: 'Gagal', detail: 'Gagal mengedit donatur', life: 3000});
					})
            },
        },
        mounted() {
            this.donaturList.getList()
                .then(response => {
                    this.listDonatur = response.data
                })
        }
    }
</script>